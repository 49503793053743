/* eslint-disable security/detect-object-injection */
import axios from 'axios';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { IActionContext, getUrlSync } from '@msdyn365-commerce/core';

export const handleUpload = async (file: File | null,token: string,domain?:string,siteName?:string,path?:string, folder?:string) => {
  try {
    const fileName = file?.name;
    
    // const sharePointSiteName = siteName || 'SZF';
    const sharePointSiteName = siteName || 'SZF-Ecommerce';
    const sharePointFilePath = path || 'Documents';
    const sharePointdomain = domain || 'zayedfestival';
    // const sharePointdomain = domain || 'visionetsys';
    
    const endpointUrl = `https://${sharePointdomain}.sharepoint.com/sites/${sharePointSiteName}/_api/web/lists/getbytitle('${sharePointFilePath}')/RootFolder/Files/Add(url='${fileName}', overwrite=true)`;
    // const endpointUrl = `https://${sharePointdomain}.sharepoint.com/${sharePointFilePath}${folder ? folder: ''}/', overwrite=true)`;
    // Set up the headers
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };

    // Upload the file

    const response = await axios.post(endpointUrl, file, {
      headers: {
        ...headers
      }
    });
    if(response && response.data){
      return {
        url: `https://visionetsys.sharepoint.com${response.data.ServerRelativeUrl}`,
      }
    }else{
      return {
        url: '',
      }
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    return {
      url:''
    }
  }
};


export const _makeExtensionProperty = (key:string,value:string) => {
  const extensionPropertyObj = [{
      Key: key,
      Value: { StringValue: value} 
  }];
  return extensionPropertyObj;
};

// Function to create attribute object
export const makeCustomAttributeUtil = (AttributeName: string, attributeValue: string | undefined) => {
  const attributeObj = {
      // @ts-ignore
      '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
      Name: AttributeName,
      TextValue: attributeValue,
      TextValueTranslations: []
  };
  return attributeObj;
};
export const addAttributeUtil = (currentAttributes: AttributeValue[], attributeObj: AttributeValue): AttributeValue[] => {
  const attrIndex = currentAttributes.findIndex((attr: AttributeValue) => attr.Name?.toLowerCase() === attributeObj.Name?.toLowerCase());
  if (attrIndex > -1) {
    currentAttributes[attrIndex] = attributeObj;
  } else {
    currentAttributes.push(attributeObj);
  }
  return currentAttributes;
};

// upgrade-v40
export const GetCheckoutReturnUrl = (actionContext: IActionContext): string => {
    let returnUrl = getUrlSync('checkout', actionContext) || '';
    const absoluteUrlRegExp = new RegExp('^(?:[a-z]+:)?//', 'i');
    const isAbsoluteUrl = absoluteUrlRegExp.test(returnUrl);
    if (window && !isAbsoluteUrl) {
        returnUrl = `${window.location.origin}${returnUrl}`;
    }

    return `${returnUrl}${!returnUrl.includes('?') ? '?' : '&'}pv=1`;
};
